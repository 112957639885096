<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addPriceMargin') : $t('message.updatePriceMargin') + ": " + marginName }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                </v-overlay>
                <v-form lazy-validation ref="priceMarginForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.name') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="PriceMargin__title"
                                :rules="[rules.title]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                @change="PriceMargin__title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.margin') + ' %' }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="PriceMargin__margin"
                                :rules="[rules.margin]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                type="number"
                                @change="PriceMargin__margin = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.add"
                    color="info"
                    small
                    @click="addPriceMargin()"
                    v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="savePriceMargin()"
                    v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {api} from "../../api";

export default {
    name: "AddPriceMargin",
    props: ['priceMarginId','dialog','updateMode','marginName'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            rules: {
                title: v => !!v || this.$t('message.required'),
                margin: v => !!v || this.$t('message.required')
            },
            validForm: true,
            PriceMargin__id: null,
            PriceMargin__title: null,
            PriceMargin__margin: null
        }
    },
    computed: {
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        addPriceMargin() {
            if(this.$refs.priceMarginForm.validate()) {
                this.loading.add = true
                const warehouseVar = {
                    title: this.PriceMargin__title,
                    margin: this.PriceMargin__margin
                }
                api
                    .post('/warehouse-vars/price-margin', {
                        warehouseVar: warehouseVar
                    })
                    .then((response) => {
                        const status = response.data.status
                        if (status == 'success') {
                            this.$toast.success(this.$t('message.successes.priceMarginAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.priceMarginNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.priceMarginNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            }
            return true
        },
        getPriceMarginToUpdateById() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/price-margin/' + this.priceMarginId)
                    .then((response) => {
                        const status = response.data.status
                        if(status == 'success'){
                            const data = response.data.data[0]
                            this.PriceMargin__id = data.PriceMargin.id
                            this.PriceMargin__title = data.PriceMargin.title
                            this.PriceMargin__margin = data.PriceMargin.margin
                            resolve('done')
                        } else {
                            reject('error')
                        }
                    })
                    .catch((err) => reject(err))
            })
        },
        resetForm() {
            this.PriceMargin__title = null
            this.PriceMargin__margin = null
            this.PriceMargin__id = null
        },
        savePriceMargin() {
            if(this.$refs.priceMarginForm.validate()) {
                this.loading.save = true
                const warehouseVar = {
                    title: this.PriceMargin__title,
                    margin: this.PriceMargin__margin
                }
                api
                    .put('/warehouse-vars/price-margin/' + this.priceMarginId, {
                        warehouseVar: warehouseVar
                    })
                    .then((response) => {
                        const status = response.data.status
                        if (status == 'success') {
                            this.$toast.success(this.$t('message.successes.priceMarginUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.priceMarginNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.priceMarginNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.priceMarginNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
            return true
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getPriceMarginToUpdateById()
                        .then(() => {
                            this.loading.get = false
                        })
                        .catch(() => {
                            this.loading.get = false
                        })
                } else {
                    this.resetForm()
                }
            } else {
                this.resetForm()
            }
            this.edit_dialog = value
        }
    },
}
</script>

<style scoped>

</style>